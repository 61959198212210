import axios, { AxiosError, AxiosResponse } from 'axios';
import { RejectionErrorTypes } from '@myriadgenetics/mgh-types';
import Logger from '../helpers/logger';
import Config from '../helpers/config';
import { mockApi } from './mock-axios';

declare global {
  interface Window {
    mghConfig: any;
  }
}

const rejectResponse = (type: RejectionErrorTypes, status: number, body: any) =>
  Promise.reject({
    type,
    status,
    body,
  });

const reject = ({ status, data }: { status: number; data: any }) =>
  rejectResponse(status >= 500 ? RejectionErrorTypes.Server : RejectionErrorTypes.Application, status, data);

const baseURL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : window && window.mghConfig && window.mghConfig.baseURL;

if (!Config.isDev && !baseURL) {
  Logger.logError('base url must be configured');
}

const api = Config.mockAPI()
  ? mockApi
  : axios.create({
      baseURL,
    });

const onResponse = (response: AxiosResponse): AxiosResponse<any> => response;
const onResponseError = (error: AxiosError | any) => reject(error.response);

api.interceptors.response.use(onResponse, onResponseError);

export default api;
