import get from 'lodash.get';
import Logger from '../logger';
import { DEFAULT_SLUG } from '../../components/survey-container/constants';

export const publicUrl = process.env.PUBLIC_URL || '';
export const externalUrls = {
  faq: 'https://myriad.com/patients-families/genetic-testing-101/genetic-testing-faqs/',
};

const SLUGS_TO_IGNORE = ['lang', 'start', 'provider', ''];
export const getSlugFromPath = (location: Location): string | null | undefined => {
  try {
    const slug = location.pathname.split('/')[1].toLowerCase();
    return SLUGS_TO_IGNORE.includes(slug) ? null : slug;
  } catch (e) {
    Logger.logError(`Potentially bad url: ${location.pathname}`);
  }
};

export const getDefaultUrlSlugByHost = (host: string): string => {
  if (!host) return DEFAULT_SLUG;

  const defaultHostSlugMap = window?.mghConfig?.DEFAULT_HOST_SLUG_MAP;
  if (defaultHostSlugMap && defaultHostSlugMap[host]) return defaultHostSlugMap[host];
  return DEFAULT_SLUG;
};

export const getUrlSlug = (mghUrl: string | undefined, location: Location) =>
  mghUrl || getSlugFromHash(location) || getSlugFromPath(location) || getDefaultUrlSlugByHost(window?.location?.host);

export const getSlugFromHash = (location: Location): string | null | undefined => location && location.hash && location.hash.replace('#', '');

export const isDemoUrl = (url: string): boolean => {
  const isDemo: boolean = url.startsWith('demo-') || url === 'demo' || url === 'prepilot';
  return isDemo;
};

export const getSlugsForGalileo = () => window?.mghConfig?.SLUGS_FOR_GALILEO ?? ['galileo', 'dtc', 'dtc2', 'dtco', 'dtc-complete', 'demo-dtc-complete'];

export const isGalileoUrl = (urlSlug: string) => getSlugsForGalileo().includes(urlSlug);

export const getPrefixForT404P = () => window?.mghConfig?.T404P_URL_PREFIX ?? 'vc-';

export const getChatbotPrefix = () => window?.mghConfig?.CHATBOT_URL_PREFIX ?? 'cb-';

export const isT404PUrl = (urlSlug: string) => urlSlug?.indexOf(getPrefixForT404P()) > -1;

export const isChatbotUrl = (urlSlug: string) => {
  const chatbotModeHosts = window?.mghConfig?.CHATBOT_MODE_HOSTS ?? [];
  const currentHref = window?.location?.href ?? '';
  const isCurrentHostInChatbotMode = chatbotModeHosts.some((href: string) => currentHref.includes(href)) ?? false;
  const isCurrentUrlSlugInChatbotPrefix = urlSlug?.indexOf(getChatbotPrefix()) > -1;

  return isCurrentHostInChatbotMode || isCurrentUrlSlugInChatbotPrefix;
};

/*
  ! [SimonMed]
  This function was created for specific and possibly temporary purposes to differentiate between standard and SimonMed MyRa-themed surveys.
  Initially, this theme (inspired by a Chatbot) was developed through the partnership between Myriad and SimonMed, and for to speed up the development process, some specific SimonMed content and features were retained.
  Gradually, we managed to remove all references to SimonMed from the code. However, there are points in the application that are not fully customizable through the Admin Tool, such as the hardcoded nodes (Cancer History, Medical History, Personal Information, Breast Cancer Risk, etc.).
  While they do not have direct references to SimonMed, there are implicit decisions involved (content, content order, breast density question, etc.).
*/
export const SIMON_MED_SLUG = 'cb-simon';
export const isSimonMedUrl = () => {
  const currentPathname = window?.location?.pathname ?? '';
  const hasSimonMedPathname = currentPathname.includes(SIMON_MED_SLUG);
  return hasSimonMedPathname;
};

const SLUGS_THAT_SKIP_TO_SURVEY = ['bypass'];
export const isSkipToSurveyUrl = (urlSlug: string) =>
  SLUGS_THAT_SKIP_TO_SURVEY.includes(urlSlug) || isGalileoUrl(urlSlug) || isT404PUrl(urlSlug) || isChatbotUrl(urlSlug);

export const getRedirectUrl = (url: string, queryParamKeys: string[] | undefined, responses: any): string => {
  // TODO consider adding error checking

  let query = '';
  if (queryParamKeys) {
    queryParamKeys.forEach((key) => {
      let val: string | null | undefined = undefined;
      if (key === 'pse_id') {
        val = get(responses, 'surveyEntrySave.surveyEntryId');
      } else {
        val = get(responses, key);
      }
      if (val !== undefined && val !== null) {
        query = `${query}&${key}=${val}`;
      }
    });

    // replace first & with ?
    query = query.replace('&', '?');
  }

  // TODO consider filters and error checking here
  return `${url}${query}`;
};

// * For more details on these types: http://hl7.org/fhir/identifier-use.
export enum PatientIdentifierTypes {
  USUAL = 'usual',
  OFFICIAL = 'official',
  TEMP = 'temp',
  SECONDARY = 'secondary',
  OLD = 'old',
}

export type PatientIdentifier = {
  key: string | null;
  type: PatientIdentifierTypes;
  responsePath: string;
};

export const getPatientIdentifiers = (): PatientIdentifier[] => window?.mghConfig?.PATIENT_IDENTIFIERS ?? [];

export const getPatientIdentifierKeys = (): (string | null)[] => {
  const patientIdentifiers: PatientIdentifier[] = window?.mghConfig?.PATIENT_IDENTIFIERS ?? [];
  return patientIdentifiers.map((pI) => pI.key);
};

export const getPatientIdentifiersUniquePaths = (): string[] => {
  const patientIdentifiers: PatientIdentifier[] = window?.mghConfig?.PATIENT_IDENTIFIERS ?? [];
  return [...new Set(patientIdentifiers.map((pI) => pI.responsePath))];
};

export const getPatientMRNUniquePath = (): string => {
  const patientIdentifiers: PatientIdentifier[] = window?.mghConfig?.PATIENT_IDENTIFIERS ?? [];
  const identifier = patientIdentifiers.find((identifier) => identifier.type === PatientIdentifierTypes.OFFICIAL);
  if (identifier) return identifier.responsePath;
  return '';
};

// Only allow alphanumeric characters and hyphens for secondary identifiers:
const SECONDARY_IDENTIFIER_ALLOWED_PATTERN = /[^a-zA-Z0-9-]*/g;
export const cleanUpSecondaryPatientIdentifier = (pI: string) => pI.replace(SECONDARY_IDENTIFIER_ALLOWED_PATTERN, '');
